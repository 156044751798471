import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TileSection from "../components/tileSection"
import ContactSection from "../components/contactSection"
import Layout from "../components/layout"

import "./realizacje.css"

const RealizacjePage = ( {data, location} ) => {

  // add url to frontmatter data based on file name (slug)
  const transformedOngoingRealisations = data.ongoingRealisationsData.nodes.map(node => {
    const file = node.internal.contentFilePath.split('/').pop();
    const slug = file.slice(0, file.lastIndexOf('.'));
    const newObject = {
      frontmatter: {
        ...node.frontmatter,
        url: slug,
      },
    };
    return newObject;
  });
  // format object as tiles + text_section
  const ongoingRealisations = {
    tiles: transformedOngoingRealisations.map(node => node.frontmatter),
    text_section: data.page.frontmatter.ongoing_realisations.text_section,
  };

  // add url to frontmatter data based on file name (slug)
  const transformedFinishedRealisations = data.finishedRealisationsData.nodes.map(node => {
    const file = node.internal.contentFilePath.split('/').pop();
    const slug = file.slice(0, file.lastIndexOf('.'));
    const newObject = {
      frontmatter: {
        ...node.frontmatter,
        url: slug,
      },
    };
    return newObject;
  });
  // format object as tiles + text_section
  const finishedRealisations = {
    tiles: transformedFinishedRealisations.map(node => node.frontmatter),
    text_section: data.page.frontmatter.finished_realisations.text_section,
  };

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Realizacje" data={data.header} />
      </AnimationWrapper>

      <section id="ongoing-realisations-tiles" className="page-section">
        <Container>
          <TileSection data={ongoingRealisations} location="ongoing-realisations" />
        </Container>
      </section>

      <section id="completed-realisations-tiles" className="page-section">
        <Container>
          <TileSection data={finishedRealisations} location="completed-realisations" />
        </Container>
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "realizacje" }, type: { eq: "page" } }) {
      frontmatter{
        title
        ongoing_realisations{
          text_section{
            title
            title_visible
            align
            subtitle
            # icon{
            #   childImageSharp {
            #     gatsbyImageData(
            #       transformOptions: {fit: COVER},
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            button_text
            button_url
            button_style
            text
          }
        }
        finished_realisations{
          text_section{
            title
            title_visible
            align
            subtitle
            # icon{
            #   childImageSharp {
            #     gatsbyImageData(
            #       transformOptions: {fit: COVER},
            #       webpOptions: {quality: 95}
            #     )
            #   }
            # }
            button_text
            button_url
            button_style
            text
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    ongoingRealisationsData: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        internal: {contentFilePath: {regex: "/(realisations)/"}},
        frontmatter: {status: {eq: "ongoing"}}}
    ) {
      nodes {
        frontmatter {
          title
          type
          active
          image {
            childImageSharp {
              gatsbyImageData(webpOptions: {quality: 95})
            }
          }
          description
        }
        internal {
          contentFilePath
        }
      }
    }
    finishedRealisationsData: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        internal: {contentFilePath: {regex: "/(realisations)/"}},
        frontmatter: {status: {eq: "finished"}}}
    ) {
      nodes {
        frontmatter {
          title
          type
          active
          image {
            childImageSharp {
              gatsbyImageData(webpOptions: {quality: 95})
            }
          }
          description
        }
        internal {
          contentFilePath
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/header-realizacje.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default RealizacjePage
